import { FC } from 'react';
import { useMyStore } from 'src/context/store';
import { ModalNames } from 'src/@types/types';
import { Dialog, DialogClose, DialogContent } from '../ui/dialog';
import SigninForm from '../auth/signin-form';
import SignUpForm from '../auth/signup-form';
import Authwrapper from '../auth/auth-wrapper';
import HomeSearch from '../home/home-search';
import ForgotPassword from '../auth/forgot-password';
import VerifyOtp from '../auth/verify-otp';
import ResetPassword from '../auth/reset-password';

const renderModalContent = (modalName: ModalNames, onClose: () => void) => {
  switch (modalName) {
    case ModalNames.SIGNIN:
      return (
        <Authwrapper
          title="Welcome"
          description="Sign in or sign up to continue"
        >
          <SigninForm afterSignInAction={onClose} />
        </Authwrapper>
      );
    case ModalNames.SIGNUP:
      return (
        <Authwrapper
          title="Welcome"
          description="Sign in or sign up to continue"
        >
          <SignUpForm />
        </Authwrapper>
      );
    case ModalNames.FORGOT_PASSWORD:
      return (
        <Authwrapper
          title="Forgot password"
          description="Type your email address below, and we’ll send you a verification otp."
        >
          <ForgotPassword />
        </Authwrapper>
      );
    case ModalNames.VERIFY_OTP:
      return (
        <Authwrapper title="Verify otp">
          <VerifyOtp />
        </Authwrapper>
      );
    case ModalNames.RESET_PASSWORD:
      return (
        <Authwrapper title="Change Password">
          <ResetPassword />
        </Authwrapper>
      );
    case ModalNames.THREAD:
      return <HomeSearch />;
    default:
      return null;
  }
};
const ModalsWrapper: FC = () => {
  const { toggleModal, isModalOpen, authModalName } = useMyStore(
    (state) => state,
  );
  const onClose = () => {
    if (authModalName === ModalNames.THREAD) {
      toggleModal(ModalNames.NULL, false);
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogClose asChild>Close</DialogClose>
      <DialogContent className="max-w-screen-sm gap-0 p-0 max-sm:max-w-xs max-sm:rounded-lg lg:max-w-[767px]">
        {renderModalContent(authModalName, onClose)}
      </DialogContent>
    </Dialog>
  );
};

export default ModalsWrapper;
