import Cookies from 'js-cookie';
import { accessTokenName, apiRoutes, refreshTokenName } from 'src/constants';
import { Axios } from 'src/lib/axios';
import { ForgotPasswordData } from 'src/lib/schema/forgot-password';
import { SignInFormData } from 'src/lib/schema/signin';
import { SignUpFormData } from 'src/lib/schema/signup';
import { handleError, handleSuccess, setLoginCookies } from 'src/lib/utils';

export const signIn = async (data: SignInFormData) => {
  try {
    const response: IUserRespons = await Axios.post(
      apiRoutes.auth.sign_in,
      data,
    );
    if (response) {
      setLoginCookies(response);
    }
    if (response.user) {
      if (response.user._id) {
        localStorage.setItem('userId', response.user?._id);
      }
      return handleSuccess(
        response.user,
        'You has been logged in successfully',
      ) as TSuccess;
    }
    return response;
  } catch (error: any) {
    console.log('errror', error);
    return handleError(error) as TError;
  }
};

export const signUp = async (data: SignUpFormData) => {
  try {
    const response = await Axios.post(apiRoutes.auth.sign_up, data);
    return handleSuccess(
      response,
      'You has been signed up, Now you can log in !',
    );
  } catch (error) {
    return handleError(error);
  }
};

export const forgotPassword = async (data: ForgotPasswordData) => {
  try {
    const response = await Axios.post(apiRoutes.auth.forgot_password, data);
    return handleSuccess(response, 'OTP sent to your email.');
  } catch (error) {
    return handleError(error);
  }
};
export const verifyOtp = async (data: ForgotPasswordData) => {
  try {
    const response = await Axios.post(apiRoutes.auth.verify_otp, data);
    return handleSuccess(response, 'OTP verified successfully.');
  } catch (error) {
    return handleError(error);
  }
};
export const resetPassword = async (data: ForgotPasswordData) => {
  try {
    const response = await Axios.post(apiRoutes.auth.reset_password, data);
    return handleSuccess(response, 'Password reset successfully.');
  } catch (error) {
    return handleError(error);
  }
};
export const signOut = async () => {
  try {
    Cookies.remove(accessTokenName);
    Cookies.remove(refreshTokenName);
    localStorage.clear();
    return { status: true, message: 'You has been logged out' };
  } catch (error) {
    return { status: false, message: 'Something went wrong, try again!' };
  }
};
