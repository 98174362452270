import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { Skeleton } from '../ui/skeleton';

export default function LibCardSkelton({ rows }: { rows: number }) {
  const len = new Array(rows).fill('');
  return (
    <>
      {len.map((item, i) => (
        <Card key={i}>
          <CardHeader>
            <CardTitle>
              <Skeleton className="h-6 w-1/3" />
            </CardTitle>
            <CardDescription>
              <Skeleton className="h-4 w-1/2" />
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <div className="flex w-full justify-between">
              <div className="flex w-full justify-start gap-1 text-center text-xs text-gray-500 dark:text-gray-100">
                <Skeleton className="h-6 w-6 rounded-full" />
                <Skeleton className="mt-1 h-4 w-40" />
              </div>
              <div className="flex justify-start gap-4 text-center text-xs text-gray-500 dark:text-gray-100">
                <div>
                  <Skeleton className="h-6 w-6 rounded-md" />
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      ))}
    </>
  );
}
