import { getConversationHistory } from 'src/services/userService';
import FollowUpInput from 'src/components/search/follow-up-input';
import { useParams } from 'react-router-dom';
import Answer from 'src/components/search/answer';
import Releted from 'src/components/search/releted';
import Sources from 'src/components/search/sources';
import Header from '../components/search/header';
import { useEffect, useRef } from 'react';
import { useMyStore } from 'src/context/store';
import { useThreadStore } from 'src/store/useThreadStore';
import { Skeleton } from 'src/components/ui/skeleton';

export default function Search() {
  const { sessionId } = useParams();
  const { isUserLoggedIn } = useMyStore((state) => state);
  const {
    thread,
    setThread,
    isNew,
    isLoading,
    setIsLoading,
    askFollowUpQuestions,
    isPrivate,
    modelName,
    temperatureValue,
  } = useThreadStore();

  const getConversation = async (id: string) => {
    setIsLoading(true);
    const response = await getConversationHistory(id);
    if (response.status) {
      setThread(response.data);
      setIsLoading(false);
    } else {
      setThread([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (sessionId && !isNew) {
      getConversation(sessionId);
    }
  }, [sessionId, isUserLoggedIn]);

  const handleFollowUpQ = async (
    value: string,
    isAdvanced: boolean,
    setAsk: (value: string) => void,
  ) => {
    if (!value && isLoading) {
      return;
    }
    setIsLoading(true);
    const body = {
      question: value,
      modelName: isPrivate
        ? modelName
          ? modelName === 'mistral-large'
            ? 'mistral.mistral-large-2402-v1:0'
            : modelName === 'claude-3-sonnet'
              ? 'anthropic.claude-3-sonnet-20240229-v1:0'
              : modelName
          : 'mistral.mistral-large-2402-v1:0'
        : 'gpt-3.5-turbo',
      temperature: isPrivate ? temperatureValue : 0.3,
    };
    const response = await askFollowUpQuestions(
      sessionId as string,
      body,
      isAdvanced,
    );
    if (response) {
      setAsk('');
      setIsLoading(false);
    }
  };

  const lastThreadElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastThreadElementRef.current) {
      lastThreadElementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [thread]);

  return (
    <>
      <Header />
      <div className=" lg:px-30 min-h-[90%] w-full pt-20 max-sm:px-4  max-sm:pb-32 sm:px-8 md:px-14 xl:px-32">
        <div className="flex flex-col gap-5">
          {thread?.length
            ? thread.map((item, i) => (
                <div
                  className="flex flex-col gap-5"
                  key={i}
                  ref={i === thread.length - 1 ? lastThreadElementRef : null}
                >
                  <h1 className="text-3xl text-black dark:text-white">
                    {item.question}
                  </h1>
                  {item.sources?.length ? (
                    <Sources sources={item.sources} desc={item.question} />
                  ) : (
                    ''
                  )}
                  {isLoading && i === thread?.length - 1 ? (
                    <div className="flex w-full items-center space-x-4">
                      <div className="w-full space-y-2">
                        <Skeleton className="h-4 w-full" />
                        <Skeleton className="h-4 w-full" />
                        <Skeleton className="h-4 w-1/2" />
                        <Skeleton className="h-4 w-1/2" />
                        <Skeleton className="h-4 w-1/3" />
                        <Skeleton className="h-4 w-1/3" />
                      </div>
                    </div>
                  ) : (
                    <Answer ans={item.AI || item.output} />
                  )}
                  {item.relatedQuestions?.length ||
                  item.related_questions?.length ? (
                    <Releted
                      list={item.relatedQuestions || item.related_questions}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="sticky bottom-0 z-10 flex h-[100px] w-full items-center justify-center bg-background">
        <div className="lg:px-30 bottom-0 w-full max-sm:absolute max-sm:px-4 sm:px-8 md:px-14 xl:px-32">
          <FollowUpInput
            loading={isLoading}
            onSend={(value, isAdvanced, setAsk) =>
              handleFollowUpQ(value, isAdvanced, setAsk!)
            }
          />
        </div>
      </div>
    </>
  );
}
