import React, { useState } from 'react';
import SearchPageHeading from './heading';
import { Check, Copy, List, SquarePen } from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { Logo } from '../icons';
import { handleCopy } from 'src/lib/utils';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
interface CopyButtonProps {
  code: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const copyCodeToClipboard = () => {
    handleCopy(code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="copy-button-container flex justify-end">
      <Button
        variant={'default'}
        size={'vs'}
        className="mt-2 p-2 text-white"
        onClick={copyCodeToClipboard}
      >
        {copied ? (
          <>
            <Check size={18} />
            &nbsp;Copied!
          </>
        ) : (
          <>
            <Copy size={18} />
            &nbsp;Copy code
          </>
        )}
      </Button>
    </div>
  );
};

export default function Answer({ ans }: { ans: string }) {
  const [copied, setCopied] = useState(false);
  const isErrorMessage =
    ans.includes('Error in faiss::FileIOReader::FileIOReader') ||
    ans.includes('could not open') ||
    ans.includes('No such file or directory');

  const displayedAnswer = isErrorMessage ? 'Something Went Wrong.' : ans;

  return (
    <div className="flex flex-col gap-2">
      <SearchPageHeading
        title="Answer"
        Icon={<Logo name="logo" width={'24'} height={'24'} />}
      />
      <Card>
        <CardContent className="px-4 py-4">
          <div>
            <Markdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeHighlight]}
              className="leading-7"
              components={{
                code({ node, className = 'blog-code', children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');

                  const codeRegex = /```[\s\S]*?```/g;
                  const codeBlocks = displayedAnswer.match(codeRegex);
                  let cb = [''];
                  let answer = '';
                  if (codeBlocks) {
                    const a = codeBlocks[0];
                    const b = a.substring(3);
                    cb = b.split(/\r?\n|\r|\n/g);
                    cb.shift();
                    cb.pop();
                    answer = cb.join('\n');
                  }
                  return match ? (
                    <>
                      <CopyButton code={answer} />
                      <SyntaxHighlighter
                        style={a11yDark}
                        language={match[1]}
                        PreTag="div"
                      >
                        {answer}
                      </SyntaxHighlighter>
                    </>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {displayedAnswer}
            </Markdown>
            <div className="mb-0 flex justify-end">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant={'default'}
                      className="p-2"
                      onClick={() => {
                        handleCopy(displayedAnswer);
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 1000);
                      }}
                    >
                      {copied ? <Check size={18} /> : <Copy size={18} />}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Copy to clipboard</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
