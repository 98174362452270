import { Route, Routes } from 'react-router-dom';
import { routes } from './pages';
import Layout from './components/layouts';

export default function Routers() {
  return (
    <Routes>
      <Route element={<Layout />}>
        {routes.map((item) => (
          <Route path={item.path} element={item.component} key={item.path} />
        ))}
      </Route>
    </Routes>
  );
}
