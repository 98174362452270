import * as React from 'react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { ThemeProviderProps } from 'next-themes/dist/types';

// Explicitly specify the type of NextThemesProvider
const ThemedNextThemesProvider =
  NextThemesProvider as React.ComponentType<ThemeProviderProps>;

export function ThemeProvider({
  children,
  ...props
}: ThemeProviderProps): JSX.Element {
  return (
    <ThemedNextThemesProvider {...props}>{children}</ThemedNextThemesProvider>
  );
}
