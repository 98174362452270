import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '../ui/sheet';
import { Separator } from '../ui/separator';
import { Checkbox } from '../ui/checkbox';
import URLFavicon from '../search/website-favicon';
import { extractDomain } from 'src/lib/utils';
import { Button } from '../ui/button';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';

type TProps = {
  sources: ISources[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  descrption: string;
};

export default function SourceSideBar({
  sources,
  open,
  setOpen,
  descrption,
}: TProps) {
  return (
    <Sheet open={open} onOpenChange={() => setOpen(false)}>
      <SheetContent
        side="right"
        className="mb-4 w-[400px] overflow-y-scroll rounded-l-lg bg-white p-0 dark:bg-background max-sm:w-full sm:w-[540px]"
      >
        <div className="sticky top-0 z-50 bg-white dark:bg-background">
          <div className="relative px-3 py-4 ">
            <SheetHeader className="p-4">
              <SheetTitle className="text-2xl font-normal dark:text-white">
                {`${sources?.length} Sources`}
              </SheetTitle>
              <SheetDescription>{descrption}</SheetDescription>
              <SheetClose asChild className="absolute right-0 top-0">
                <Button className="h-9 w-9 p-0" variant="ghost">
                  <X size={20} />
                </Button>
              </SheetClose>
            </SheetHeader>
          </div>
          <Separator />
        </div>
        <div>
          {sources?.length &&
            sources.map((item, i) => (
              <div className="w-full px-4 py-5" key={i}>
                <div className="flex gap-2">
                  {/* <Checkbox id="terms" className="h-6 w-6" /> */}
                  <Link to={item.url} className="w-full" target="_blank">
                    <div className="w-full rounded-md bg-black/10 px-3 py-2 hover:bg-black/20 dark:bg-foreground dark:hover:bg-accent">
                      <div className="flex flex-col gap-2">
                        <h1 className="truncate text-lg text-black dark:text-white">{`${++i}. ${item.title}`}</h1>
                        <div className="flex gap-2 text-center">
                          <URLFavicon websiteUrl={item.url} />
                          <span className="truncate text-sm text-gray-600 dark:text-gray-50">
                            {extractDomain(item.url)}
                          </span>
                        </div>
                        {/* <span className="text-sm text-black/90 dark:text-white">
                        Description
                      </span> */}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </SheetContent>
    </Sheet>
  );
}
