import { Outlet } from 'react-router-dom';
import { Sidebar } from '../sidebar';
import { useMyStore } from 'src/context/store';
import { useEffect } from 'react';
import { checkIsUserLoggedIn } from 'src/lib/utils';
import { ModalNames } from 'src/@types/types';

export default function Layout({
  children,
}: Readonly<{
  children?: React.ReactNode;
}>) {
  const { drawer, isUserLoggedIn, setUserStatus, toggleModal } = useMyStore(
    (state) => state,
  );

  useEffect(() => {
    setUserStatus(checkIsUserLoggedIn());
    if (!isUserLoggedIn) {
      toggleModal(ModalNames.SIGNIN, true);
    }
  }, [isUserLoggedIn]);

  return (
    <>
      <Sidebar />
      <main
        className={`${drawer ? 'sm:ml-[100px]' : 'sm:ml-[300px]'} h-screen transition-all duration-300 ease-in-out sm:pt-3`}
      >
        {children || <Outlet />}
      </main>
    </>
  );
}
