import { z } from 'zod';

// Sign Up Form Schema
export const SignUpFormSchema = z.object({
  userName: z
    .string()
    .min(1, 'username is required')
    .min(3, { message: 'username must be at least 3 characters long' }),
  email: z
    .string()
    .min(1, 'Email is required')
    .email({ message: 'Invalid email address' }),
  password: z
    .string()
    .min(1, 'Password is required')
    .min(8, { message: 'Password must be at least 8 characters long' })
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~}{[\]~!@#$%^&*()_\-+=:;'"<>?/.,\\])[A-Za-z\d~}{[\]~!@#$%^&*()_\-+=:;'"<>?/.,\\]{8,12}$/,
      {
        message:
          'Password must be 8 to 12 characters long and contain at least one uppercase letter, one lowercase letter, and one special character.',
      },
    ),
});
export type SignUpFormData = z.infer<typeof SignUpFormSchema>;
