import { List } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import SearchPageHeading from './heading';
import { Link } from 'react-router-dom';
import URLFavicon from './website-favicon';
import { extractDomain } from 'src/lib/utils';
import SourceSideBar from '../sidebar/source-sidebar';
import { useState } from 'react';

export default function Sources({
  sources,
  desc,
}: {
  sources: ISources[];
  desc: string;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [descrption, setDescrption] = useState<string>('');

  const handleViewMore = () => {
    setOpen(true);
    setDescrption(desc);
  };

  const giveUrl = (url: string) => {
    const abc = extractDomain(url);
    console.log(abc, 'abc', abc?.length);
    const len = abc?.length || 0;
    const newAbc = len > 15 ? abc?.substring(0, 14) + `...` : abc;
    return newAbc;
  };
  return (
    <>
      <div className="flex flex-col gap-2">
        <SearchPageHeading
          title="Sources"
          Icon={<List />}
          viewMore={sources?.length > 3 ? true : false}
          handleViewMore={handleViewMore}
        />
        <div
          className={`${sources.some((source) => source.pages) ? 'grid grid-cols-1 gap-3 xl:grid-cols-3' : 'grid grid-cols-2 gap-3 xl:grid-cols-3'}`}
        >
          {sources?.map(
            (item, i) =>
              i <= 2 && (
                <Card
                  key={i}
                  className="relative flex flex-col justify-between"
                >
                  {/* Check if URL exists */}
                  {item.url ? (
                    // If URL exists, wrap title and domain in a Link
                    <>
                      <Link to={item.url} target="_blank">
                        <CardHeader className="px-6 py-4">
                          <CardTitle className="text-md font-normal">
                            {item.title}
                          </CardTitle>
                        </CardHeader>
                      </Link>
                      <Link to={item.url} target="_blank">
                        <div className="relative bottom-0 flex items-center justify-start gap-1 px-4 pb-3">
                          <URLFavicon websiteUrl={item.url} />
                          <span className="truncate text-sm text-gray-600 dark:text-gray-50">
                            {extractDomain(item.url)}
                          </span>
                        </div>
                      </Link>
                    </>
                  ) : (
                    // If URL doesn't exist, render title and domain without Link
                    <>
                      <CardHeader className="px-6 py-4">
                        <CardTitle className="text-md font-normal">
                          {item.title}
                        </CardTitle>
                      </CardHeader>
                      <CardContent className="relative bottom-0 flex gap-2 p-0 px-6 pb-4">
                        {item.pages ? (
                          <>
                            <span className="flex text-[15px] text-[#64645F] dark:text-gray-50">
                              Page
                            </span>{' '}
                            {item.pages
                              .split(',')
                              .slice(0, 6)
                              .map((page, index) => (
                                <span
                                  key={index}
                                  className="flex h-[25px] w-[25px] items-center justify-center rounded-[7px] bg-[#ebebeb] p-2 text-[14px] font-normal text-[#64645F]"
                                >
                                  {page.trim()}
                                </span>
                              ))}
                            {item.pages.split(',').length > 6 && (
                              <span className="truncate">...</span>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </CardContent>
                      {item.url ? (
                        <div className="relative bottom-0 flex items-center justify-start gap-1 px-4 pb-3">
                          <URLFavicon websiteUrl={item.url} />
                          <span className="text-sm text-gray-600 dark:text-gray-50">
                            {extractDomain(item.url)}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Card>
              ),
          )}
        </div>
      </div>
      <SourceSideBar
        sources={sources}
        open={open}
        setOpen={setOpen}
        descrption={descrption}
      />
    </>
  );
}
