import { ArrowUp, CirclePlus, RotateCw } from 'lucide-react';
import { Input } from '../ui/input';
import { useState, useRef, useEffect } from 'react';
import { Button } from '../ui/button';
import {
  createSession,
  deleteFiles,
  scrapeData,
} from 'src/services/userService';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import AttachedFilePopover from '../home/attachedFile';
import { useThreadStore } from 'src/store/useThreadStore';
import { Switch } from '../ui/switch';
import { boolean } from 'zod';
import { Textarea } from '../ui/textarea';

type TScrapState = {
  loading: boolean;
  uploadedContent?: string[];
};
export default function FollowUpInput({
  onSend,
  loading,
}: {
  onSend: (
    value: string,
    isAdvanced: boolean,
    setAsk?: (value: string) => void,
  ) => void;
  loading: boolean;
}) {
  const { sessionId } = useParams();
  const [ask, setAsk] = useState('');
  const [tempAsk, setTempAsk] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileExtension, setFileExtension] = useState<string>('');

  const { scrappedContent, isAdvance, setIsAdvance } = useThreadStore();

  const [scrapState, setScrapState] = useState<TScrapState>({
    loading: false,
    uploadedContent: [],
  });
  useEffect(() => {
    console.log(
      'scrapState.uploadedContent',
      scrapState.uploadedContent,
      isAdvance,
    );
  });
  useEffect(() => {
    const arrayi: string[] = [];
    scrappedContent?.forEach((element) => {
      arrayi.push(element.name);
    });
    setScrapState(
      (prev) =>
        ({
          ...prev,
          loading: false,
          uploadedContent: arrayi,
        }) as TScrapState,
    );
  }, [scrappedContent]);

  const [isHovered, setIsHovered] = useState(false);

  const handleSend = () => {
    setTempAsk('');
    if (ask.trim() !== '') {
      onSend(ask, isAdvance, () => setAsk(''));
    }
  };

  const handleNotSend = () => {
    setTempAsk('');
  };

  const handleAttachClick = () => {
    if (fileInputRef.current) {
      // Trigger click event on file input
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileName = e.target.files?.[0]?.name as string;
    const file = e.target.files?.[0];
    if (!file) return;
    const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB
    if (fileSizeInMB > 200) {
      toast.warning('Please select a file smaller than 200 MB.');
      return;
    }
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      const base64Data = event.target.result.split(',')[1];
      const fileExtension1 = fileName.split('.').pop()?.toLowerCase();
      let contentType = '';
      if (fileExtension1 === 'pdf') {
        contentType = 'pdf';
      } else if (fileExtension1 === 'csv') {
        contentType = 'csv';
      } else if (fileExtension1 === 'txt') {
        contentType = 'txt';
      } else if (fileExtension1 === 'xlsx') {
        contentType = 'xlsx';
      } else if (fileExtension1 === 'xls') {
        contentType = 'xls';
      }
      setFileExtension(fileExtension);
      const body = {
        name: fileName,
        contentType,
        fileContent: base64Data,
      };
      setScrapState((prev) => ({ ...prev, loading: true }));
      const res = await scrapeData(sessionId as string, body);

      if (res.status) {
        setScrapState(
          (prev) =>
            ({
              ...prev,
              loading: false,
              uploadedContent: prev.uploadedContent
                ? [...prev.uploadedContent, fileName]
                : [fileName],
            }) as TScrapState,
        );
        toast.message(res.message);
      } else {
        setScrapState((prev) => ({ ...prev, loading: false }));
        toast.error(res.message);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleClear = async () => {
    const response = await deleteFiles(sessionId as string);
    if (response.status) {
      setScrapState((prev) => ({
        ...prev,
        uploadedContent: [],
        loading: false,
      }));
      toast.success(response.message);
    }
    toast.error(response.message);
  };
  const handleIsAdvance = () => {
    console.log('setIsAdavance', isAdvance);
    // setIsAdavance((prev: any) => !prev);
    setIsAdvance(!isAdvance);
  };
  return (
    <div className="w-full rounded-md">
      <div className="mb-4 mt-[30%] hidden w-full rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-700 max-sm:block">
        <div className="rounded-t-lg bg-white px-4 py-4 dark:bg-gray-800">
          <Textarea
            disabled={loading}
            value={tempAsk}
            onChange={(e) => {
              setAsk(e.target.value);
              setTempAsk(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && ask.trim() !== '' && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            className="textBox-shadow relative w-full border border-secondary bg-white p-6  dark:border-[#333333] dark:bg-black dark:text-white dark:shadow-none dark:placeholder:mt-2 dark:placeholder:text-[#8d8d8d]"
            placeholder="Ask follow-up"
          />
        </div>
        <div className="flex items-center justify-between border-t px-3 py-2 dark:border-gray-600">
          <div className="flex w-full justify-between">
            <AttachedFilePopover
              fileName={false}
              list={scrapState.uploadedContent}
              onAdd={handleAttachClick}
              onClear={handleClear}
              isLoading={scrapState.loading || loading}
              Comp={() => (
                <Button
                  variant={'ghost'}
                  // className="gap-1"
                  onClick={handleAttachClick}
                  disabled={scrapState.loading || loading}
                  className={` dark:text-secondary ${isHovered ? 'icon-hovered gap-1 hover:text-accent-foreground' : 'gap-1'}`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <span>
                    {scrapState.loading ? (
                      <RotateCw className="h-4 w-4 animate-spin" />
                    ) : (
                      <CirclePlus size={16} />
                    )}
                  </span>
                </Button>
              )}
            />
            <div className="flex justify-end">
              {scrapState.uploadedContent &&
                scrapState.uploadedContent.map((fileName, index) => {
                  const fileExtension = fileName
                    .split('.')
                    .pop()
                    ?.toLowerCase();
                  return (
                    fileExtension && ( // This conditional ensures fileExtension is not null or undefined
                      <div key={index}>
                        {fileExtension === 'csv' ||
                        fileExtension === 'xlsx' ||
                        fileExtension === 'xls' ? (
                          <div
                            className={`right-[156px] flex cursor-pointer items-center  rounded-full text-gray-500 dark:text-secondary max-sm:right-16 sm:right-[50px] md:right-[100px] xl:right-[177px]`}
                          >
                            <Switch
                              checked={isAdvance}
                              onCheckedChange={handleIsAdvance}
                            />
                            <Button
                              className="px-2 pb-2 font-semibold text-accent-foreground disabled:cursor-not-allowed disabled:!text-[#64645e]  dark:bg-foreground dark:text-[#8D8D8D]"
                              disabled={isAdvance === false}
                            >
                              Analyze
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )
                  );
                })}
              <div
                className={`flex h-[38px] w-[38px] cursor-pointer items-center justify-center rounded-full bg-slate-100 p-2 text-gray-500 dark:bg-[#09AC961A] dark:text-secondary ${!loading && 'hover:bg-accent hover:text-accent-foreground'}`}
              >
                <ArrowUp
                  size={20}
                  onClick={loading ? handleNotSend : handleSend}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block max-sm:hidden">
        <Input
          disabled={loading}
          value={tempAsk}
          onChange={(e) => {
            setAsk(e.target.value);
            setTempAsk(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && ask.trim() !== '' && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
          placeholder="Ask follow-up"
          className="textBox-shadow relative w-full border border-secondary bg-white p-6 pl-12 focus:shadow-2xl dark:border-[#333333] dark:bg-black dark:text-white dark:shadow-none dark:placeholder:mt-2 dark:placeholder:text-[#8d8d8d] max-sm:pl-8"
        />
        <div>
          <div
            className={`absolute left-[128px] top-[32px] text-secondary max-sm:left-3 sm:left-[28px] md:left-[53px] xl:left-[130px] ${scrapState.loading || (loading && 'cursor-not-allowed')} `}
          >
            <AttachedFilePopover
              fileName={false}
              list={scrapState.uploadedContent}
              onAdd={handleAttachClick}
              onClear={handleClear}
              isLoading={scrapState.loading || loading}
              Comp={() => (
                <Button
                  variant={'ghost'}
                  // className="gap-1"
                  onClick={handleAttachClick}
                  disabled={scrapState.loading || loading}
                  className={` dark:text-secondary ${isHovered ? 'icon-hovered gap-1 hover:text-accent-foreground' : 'gap-1'}`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <span>
                    {scrapState.loading ? (
                      <RotateCw className="h-4 w-4 animate-spin" />
                    ) : (
                      <CirclePlus size={16} />
                    )}
                  </span>
                </Button>
              )}
            />
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".pdf, .csv, .txt, .xlsx, .xls"
            />
          </div>
          {scrapState.uploadedContent &&
            scrapState.uploadedContent.map((fileName, index) => {
              const fileExtension = fileName.split('.').pop()?.toLowerCase();
              return (
                fileExtension && ( // This conditional ensures fileExtension is not null or undefined
                  <div key={index}>
                    {fileExtension === 'csv' ||
                    fileExtension === 'xlsx' ||
                    fileExtension === 'xls' ? (
                      <div
                        className={`absolute right-[156px] top-[32px] flex cursor-pointer items-center  rounded-full text-gray-500 dark:text-secondary max-sm:right-16 sm:right-[50px] md:right-[100px] xl:right-[177px]`}
                      >
                        <Switch
                          checked={isAdvance}
                          onCheckedChange={handleIsAdvance}
                        />
                        <Button
                          className="px-2 pb-2 font-semibold text-accent-foreground hover:!bg-transparent disabled:cursor-not-allowed disabled:!text-[#64645e] dark:text-[#8D8D8D]"
                          disabled={isAdvance === false}
                        >
                          Analyze
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )
              );
            })}
          <div
            className={`absolute right-[136px] top-[32px] cursor-pointer rounded-full bg-slate-100 p-2 text-gray-500 dark:bg-[#09AC961A] dark:text-secondary max-sm:right-6 sm:right-[40px] md:right-[65px] xl:right-[140px] ${!loading && 'hover:bg-accent hover:text-accent-foreground'}`}
          >
            <ArrowUp size={20} onClick={loading ? handleNotSend : handleSend} />
          </div>
        </div>
      </div>
    </div>
  );
}
