import { startConversation } from 'src/services/userService';
import { create } from 'zustand';

// Define initial state
const initialState = {
  thread: [],
  isNew: false,
  isLoading: false,
  isRecent: false,
  scrappedContent: [],
  isAdvance: false,
  isPrivate: false,
  temperatureValue: 0,
  topKValue: 0,
  modelName: 'mistral-large',
};

type TThreadState = {
  thread: IChatResponse[];
  isNew: boolean;
  isLoading: boolean;
  isRecent: boolean;
  scrappedContent: TindexingDetails[];
  isAdvance: boolean;
  isPrivate: boolean;
  temperatureValue: number;
  topKValue: number;
  modelName: string;
};

type TThreadAction = {
  startThread: (
    sessionId: string,
    body: any,
    isPrivate: boolean,
    isAdvanced?: boolean,
  ) => Promise<void>;
  askFollowUpQuestions: (
    sessionId: string,
    body: any,
    isAdvanced?: boolean,
  ) => Promise<boolean>;
  setThread: (thread: IChatResponse[]) => void;
  setIsNew: (flag: boolean) => void;
  setIsLoading: (flag: boolean) => void;
  setIsRecent: (flag: boolean) => void;
  setScrappedContent: (scrappedContent: TindexingDetails[]) => void;
  setIsAdvance: (flag: boolean) => void;
  setIsPrivate: (flag: boolean) => void;
  setTemperatureValue: (flag: number) => void;
  setTopKValue: (flag: number) => void;
  setModalName: (flag: string) => void;
};

type TThreadStore = TThreadState & TThreadAction;

// Create ThreadStore
export const useThreadStore = create<TThreadStore>((set) => ({
  ...initialState,
  startThread: async (
    sessionId: string,
    body: any,
    isPrivate: boolean,
    isAdvanced = false,
  ) => {
    try {
      set((state) => ({
        ...state,
        isNew: true,
        isLoading: true,
        thread: [body],
      }));
      const response = await startConversation(sessionId, body, isAdvanced);
      if (response.status) {
        const storedData = localStorage.getItem('detail');
        // Parse the existing data if it exists
        const dataArray = storedData ? JSON.parse(storedData) : [];
        dataArray.push({
          sessionId,
          ...body,
          isPrivate,
        });
        localStorage.setItem('detail', JSON.stringify(dataArray));
        set((state) => ({
          ...state,
          isLoading: false,
          isRecent: true,
          thread: [{ ...response.data?.answer, ...body }],
        }));
      } else {
        // Handle error scenario if needed
        set((state) => ({ ...state, isLoading: false }));
        console.error('Conversation start failed:', response.status);
      }
    } catch (error) {
      // Handle network errors or other unexpected errors
      set((state) => ({ ...state, isLoading: false }));
      console.error('Error starting conversation:', error);
    }
  },
  setThread: (thread: IChatResponse[]) =>
    set((state) => ({
      ...state,
      thread,
    })),
  setIsNew: (flag: boolean) =>
    set((state) => ({
      ...state,
      isNew: flag,
    })),
  setIsAdvance: (flag: boolean) =>
    set((state) => ({
      ...state,
      isAdvance: flag,
    })),
  setIsPrivate: (flag: boolean) =>
    set((state) => ({
      ...state,
      isPrivate: flag,
    })),
  setIsRecent: (flag: boolean) =>
    set((state) => ({
      ...state,
      isRecent: flag,
    })),
  setIsLoading: (flag: boolean) =>
    set((state) => ({
      ...state,
      isLoading: flag,
    })),
  setTemperatureValue: (flag: number) =>
    set((state) => ({
      ...state,
      temperatureValue: flag,
    })),
  setTopKValue: (flag: number) =>
    set((state) => ({
      ...state,
      topKValue: flag,
    })),
  setModalName: (flag: string) =>
    set((state) => ({
      ...state,
      modelName: flag,
    })),
  setScrappedContent: (scrappedContent: TindexingDetails[]) =>
    set((state) => ({
      ...state,
      scrappedContent,
    })),
  askFollowUpQuestions: async (
    sessionId: string,
    body: any,
    isAdvanced = false,
  ) => {
    try {
      set((state) => ({
        ...state,
        isLoading: true,
        thread: [...state.thread, body],
      }));
      const response = await startConversation(sessionId, body, isAdvanced);
      if (response.status) {
        Object.assign(body, response.data?.answer);
        set((state) => ({
          ...state,
          isLoading: false,
          thread: [...state.thread],
        }));
        return true;
      } else {
        // Handle error scenario if needed
        Object.assign(body, { AI: response.message });
        set((state) => ({ ...state, isLoading: false }));
        console.error('Conversation start failed:', response.status);
        return false;
      }
    } catch (error) {
      // Handle network errors or other unexpected errors
      set((state) => ({ ...state, isLoading: false }));
      console.error('Error starting conversation:', error);
      return false;
    }
  },
}));
