import React, { useState, useEffect } from 'react';

const URLFavicon = ({ websiteUrl }: { websiteUrl: string }) => {
  const baseUrl = 'https://www.google.com/s2/favicons?sz=64&domain_url=';

  return (
    <>
      {websiteUrl && (
        <img
          className="rounded-full bg-gray-100 p-1"
          height={22}
          width={22}
          src={`${baseUrl}${encodeURIComponent(websiteUrl)}`}
          alt={`Favicon for ${websiteUrl}`}
        />
      )}
    </>
  );
};

export default URLFavicon;
