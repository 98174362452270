import { LucideIcon } from 'lucide-react';
import { ReactNode } from 'react';

export interface SidebarItems {
  links: {
    label: string;
    href: string;
    icon?: LucideIcon;
    modal?: ModalNames;
  }[];
  subLink: {
    modal?: ModalNames;
    key: number;
    label: string;
    icon: React.ComponentType<any>;
    href: string;
    sessionId: string;
    isPrivate?: boolean;
  }[];
  auth: {
    label: string;
    icon?: LucideIcon;
    modal?: ModalNames;
  }[];
  extras: (drawer?: boolean, onClick?: () => void) => ReactNode;
}

export enum ModalNames {
  NULL = '',
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  THREAD = 'thread',
  FORGOT_PASSWORD = 'forgot-password',
  VERIFY_OTP = 'verify-otp',
  RESET_PASSWORD = 'reset-password',
}

export type TActions = {
  // AUTH ACTIONS
  setUserStatus: (flag: boolean) => void;
  // DRAWER ACTIONS
  toggleDrawer: () => void;
  // MODAL ACTIONS
  toggleModal: (name: ModalNames, flag?: boolean) => void;
  setUser: (user: IUser) => void;
};
export type TStates = {
  // AUTH STATES
  isUserLoggedIn: boolean;
  drawer: boolean;
  // MODAL STATES
  isModalOpen: boolean;
  authModalName: ModalNames;
  user: IUser;
};

export type TStore = TStates & TActions;

export type TThreadAction = {
  startThread: (sessionId: string, body: any) => void;
};
