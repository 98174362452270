import { Trash2, Settings } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMyStore } from 'src/context/store';
import { routes } from 'src/pages';
import {
  deleteSession,
  getSession,
  updateSession,
} from 'src/services/userService';
import { Input } from '../ui/input';
import { toast } from 'sonner';
import SubmitButton from '../auth/submit-button';
import { useThreadStore } from 'src/store/useThreadStore';
import PrivateDetail from '../sidebar/private-detail';

export default function Header() {
  const { drawer } = useMyStore((state) => state);
  const [data, setData] = useState<ILibraryResponse>({} as ILibraryResponse);
  const { sessionId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);
  const nav = useNavigate();
  const { isUserLoggedIn } = useMyStore((state) => state);
  const { setIsRecent, setScrappedContent, isPrivate, setIsPrivate } =
    useThreadStore();
  const [open, setOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (flag) {
      inputRef.current?.focus();
    }
  }, [flag]);

  const getSessionDetails = async (id: string) => {
    setLoading(true);
    const res = await getSession(id);
    if (res.status) {
      setData(res.data);
      setIsPrivate(res.data?.isPrivate);
      setLoading(false);
      setScrappedContent(res.data.indexingDetails as TindexingDetails[]);
    } else {
      setData({} as ILibraryResponse);
      setLoading(false);
    }
  };

  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const sessionName = (e.target as HTMLFormElement).sessionName.value;
    if (sessionName === data?.sessionName) {
      return;
    }
    setLoading(true);
    const res = await updateSession(sessionId as string, { sessionName });
    if (res.status) {
      setLoading(false);
      toast.success(res.message);
      getSessionDetails(sessionId as string);
      setFlag(false);
    } else {
      setLoading(false);
      toast.error(res.message);
    }
  };
  const onDoubleClick = () => {
    setFlag(true);
  };

  const onBlurHandler = async (e: any) => {
    e.preventDefault();
    const sessionName = (e.target as HTMLFormElement).value;
    if (sessionName === data?.sessionName) {
      return;
    }
    setLoading(true);
    const res = await updateSession(sessionId as string, { sessionName });
    if (res.status) {
      setLoading(false);
      toast.success(res.message);
      getSessionDetails(sessionId as string);
      setFlag(false);
    } else {
      setLoading(false);
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (sessionId && isUserLoggedIn) {
      getSessionDetails(sessionId);
    }
  }, [sessionId, isUserLoggedIn]);

  const handleDelete = async () => {
    setDelLoading(true);
    const res = await deleteSession(sessionId as string);
    if (res.status) {
      setIsRecent(true);
      nav(routes[0].path);
      setDelLoading(false);
    } else {
      setDelLoading(false);
    }
  };
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  return (
    <header
      className={`fixed left-0 top-0 z-10 max-sm:hidden ${drawer ? 'pl-[100px]' : 'pl-[300px]'} w-full border-b bg-gray-50 py-4 transition-all duration-300 ease-in-out dark:bg-background`}
    >
      <div className="lg:px-30 flex items-center justify-between text-center max-sm:px-4  sm:px-8 md:px-14 xl:px-32">
        {data.isPrivate ? (
          <div className="flex  gap-3 rounded-md border border-[#09AC96] bg-[#e0f0ee] px-3 py-[3px] text-center text-lg dark:border-[#09AC96] dark:bg-foreground dark:text-[#09AC96]">
            <span className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M7.5 8.99774H10.5V11.2477H7.5V8.99774ZM9.75 7.49774C9.75 7.29883 9.67098 7.10806 9.53033 6.96741C9.38968 6.82676 9.19891 6.74774 9 6.74774C8.80109 6.74774 8.61032 6.82676 8.46967 6.96741C8.32902 7.10806 8.25 7.29883 8.25 7.49774V8.24774H9.75V7.49774Z"
                  fill="#09AC96"
                />
                <path
                  d="M15.375 3.74776C13.1936 3.74776 10.323 3.01201 9.3 1.64776C9.26359 1.60341 9.2178 1.56768 9.16593 1.54315C9.11405 1.51861 9.05738 1.50589 9 1.50589C8.94262 1.50589 8.88595 1.51861 8.83407 1.54315C8.7822 1.56768 8.73641 1.60341 8.7 1.64776C7.677 3.01164 4.80638 3.74776 2.625 3.74776C2.52554 3.74776 2.43016 3.78727 2.35984 3.8576C2.28951 3.92792 2.25 4.0233 2.25 4.12276V8.99776C2.25 13.1213 5.83087 15.6075 8.89725 16.4831C8.96436 16.5026 9.03564 16.5026 9.10275 16.4831C12.1691 15.6075 15.75 13.1213 15.75 8.99814V4.12314C15.75 4.02368 15.7105 3.9283 15.6402 3.85797C15.5698 3.78764 15.4745 3.74776 15.375 3.74776ZM11.25 11.6228C11.25 11.7222 11.2105 11.8176 11.1402 11.8879C11.0698 11.9583 10.9745 11.9978 10.875 11.9978H7.125C7.02554 11.9978 6.93016 11.9583 6.85983 11.8879C6.78951 11.8176 6.75 11.7222 6.75 11.6228V8.62276C6.75 8.5233 6.78951 8.42792 6.85983 8.3576C6.93016 8.28727 7.02554 8.24776 7.125 8.24776H7.5V7.49776C7.5 7.09994 7.65804 6.7184 7.93934 6.4371C8.22064 6.1558 8.60218 5.99776 9 5.99776C9.39782 5.99776 9.77936 6.1558 10.0607 6.4371C10.342 6.7184 10.5 7.09994 10.5 7.49776V8.24776H10.875C10.9745 8.24776 11.0698 8.28727 11.1402 8.3576C11.2105 8.42792 11.25 8.5233 11.25 8.62276V11.6228Z"
                  fill="#09AC96"
                />
              </svg>
              <p className=" py-1 text-[15px] font-semibold text-[#09AC96]">
                Private
              </p>
            </span>
          </div>
        ) : (
          <div className="flex  gap-3 rounded-md border border-[#878787]  px-3 py-[3px] text-center text-lg dark:border-[#878787] dark:bg-foreground dark:text-[#09AC96]">
            <span className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.7954 4.29545C10.7954 6.25356 9.20803 7.84091 7.24993 7.84091C5.29183 7.84091 3.70448 6.25356 3.70448 4.29545C3.70448 2.33735 5.29183 0.75 7.24993 0.75C9.20803 0.75 10.7954 2.33735 10.7954 4.29545ZM7.25 8.43182C9.01249 8.47751 10.7402 8.93269 12.2964 9.76136C12.7346 9.98423 13.1024 10.3242 13.359 10.7436C13.6156 11.1629 13.751 11.6452 13.75 12.1368V12.5682C13.75 12.8816 13.6255 13.1822 13.4039 13.4039C13.1822 13.6255 12.8816 13.75 12.5682 13.75H1.93182C1.61838 13.75 1.31778 13.6255 1.09615 13.4039C0.874513 13.1822 0.75 12.8816 0.75 12.5682V12.125C0.751201 11.6354 0.887564 11.1556 1.14406 10.7385C1.40055 10.3215 1.76722 9.98332 2.20364 9.76136C3.75984 8.93269 5.48751 8.47751 7.25 8.43182Z"
                  fill="#878787"
                />
              </svg>
              <p className="py-1 text-[15px] font-semibold text-[#878787]">
                Public
              </p>
            </span>
          </div>
        )}
        <div className="rounded-md bg-black/10 px-2 py-1 text-center text-lg dark:border dark:border-[#333333] dark:bg-foreground dark:text-[#8D8D8D]">
          {flag ? (
            <form onSubmit={onFormSubmit}>
              <Input
                ref={inputRef}
                onBlur={onBlurHandler}
                name="sessionName"
                className="border-none bg-transparent p-0 text-center text-lg"
                defaultValue={
                  data?.sessionName ? data?.sessionName : 'Untitled'
                }
              />
            </form>
          ) : (
            <div onDoubleClick={onDoubleClick} className="px-3 py-1">
              {loading
                ? 'Loading...'
                : data?.sessionName
                  ? data.sessionName.length > 50
                    ? `${data.sessionName.substring(0, 50)}...`
                    : data.sessionName
                  : 'Untitled'}
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2">
          <SubmitButton
            className={`rounded-md bg-black/20 px-3 py-2 text-gray-700 dark:border dark:border-[#333333] dark:bg-foreground dark:text-[#8D8D8D]  ${isPrivate ? 'visible opacity-100' : 'invisible opacity-0'}`}
            onClick={handleDrawerToggle}
          >
            <Settings className="" size={20} />
          </SubmitButton>
          <SubmitButton
            pending={delLoading}
            className={`rounded-md bg-black/20 px-3 py-2 text-gray-700 dark:border dark:border-[#333333] dark:bg-foreground dark:text-[#8D8D8D] ${delLoading ? 'flex items-center justify-center bg-gray-200 pl-3 text-gray-700' : ''}`}
            onClick={handleDelete}
          >
            {!delLoading && <Trash2 className="" size={20} />}
          </SubmitButton>
        </div>
      </div>
      <PrivateDetail open={open} setOpen={setOpen} />
    </header>
  );
}
