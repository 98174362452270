export const accessTokenName = 'accessToken';
export const refreshTokenName = 'refreshToken';
export const baseURL = process.env.REACT_APP_BACKEND_BASE_URL as string;

export const recomandedList = [
  '🐦 Most dangerous bird in the world',
  '🎵 The best-selling music artist',
  '🥶 How to get rid of a cold?',
  '🌔 Why does the moon change shape?',
];

export const apiRoutes = {
  auth: {
    sign_in: '/login',
    sign_up: '/signup',
    forgot_password: '/forgot-password',
    verify_otp: '/verify-otp',
    reset_password: '/reset-password',
  },
  user: {
    profile: (userId: string) => `/users/${userId}`,
    sessions: {
      all: (userId: string) => '/session/getAll/' + userId,
      create: (isPrivate: boolean) =>
        `/session/createSession?isPrivate=${isPrivate}`,
      single: (sessionId: string, userId: string) =>
        `/session/getSession/${userId}/${sessionId}`,
      delete: (sessionId: string, userId: string) =>
        `/session/delete/${userId}/${sessionId}`,
      deleteAll: (userId: string) => `/session/deleteAll/${userId}`,
      update: (sessionId: string, userId: string) =>
        `/session/${userId}/${sessionId}`,
      deleteFiles: (sessionId: string, userId: string) =>
        `/session/deleteVector?userId=${userId}&sessionId=${sessionId}`,
    },
  },
  dataIndexing: {
    scrapeData: (sessionId: string, userId: string) =>
      `/session/scrapeData?sessionId=${sessionId}&userId=${userId}`,
  },
  conversation: {
    query: (sessionId: string, userId: string, isAdvanced: boolean) =>
      `/conversation/chatQuery?sessionId=${sessionId}&userId=${userId}&isAdvanced=${isAdvanced}`,
    history: (sessionId: string, userId: string) =>
      `/conversation/getHistory/${userId}/${sessionId}`,
  },
};
