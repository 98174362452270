import { clsx, type ClassValue } from 'clsx';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { accessTokenName, refreshTokenName } from 'src/constants';
import { twMerge } from 'tailwind-merge';
import relative from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleError = (error: any): TError => {
  if (error?.response?.data?.error) {
    return { message: error.response.data.error, status: false, data: null };
  }
  return { message: 'Something went wrong', status: false, data: null };
};

export const handleSuccess = (response: any, msg: string): TSuccess => {
  if (response) {
    return { data: response, status: true, message: msg };
  }
  return { data: null, status: false, message: 'Somthing went wrong' };
};

export const setLoginCookies = (data: Partial<IUserRespons>) => {
  if (data.access_token)
    Cookies.set(accessTokenName, data.access_token, {
      // expires: new Date(data.accessTokenExp * 1000),
      path: '/',
    });
  if (data.refresh_token)
    Cookies.set(refreshTokenName, data.refresh_token, {
      // expires: new Date(data.refreshTokenExp),
      path: '/',
    });
};

export const checkIsUserLoggedIn = (
  accessToken = Cookies.get(accessTokenName),
): boolean => {
  try {
    if (accessToken === undefined) return false;
    const decodedToken = JSON.parse(atob(accessToken?.split('.')[1]));
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    if (expirationTime < currentTime) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getUserId = (): string => {
  try {
    const id = localStorage.getItem('userId');
    return id ? id : '';
  } catch (error) {
    return '';
  }
};

export const extractDomain = (url: string) => {
  try {
    if (!url) {
      return;
    }
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error('Error parsing URL:', error);
    return null;
  }
};

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  const successful = document.execCommand('copy');
  const msg = successful ? 'successful' : 'unsuccessful';
  console.log('Fallback: Copying text command was ' + msg);
  document.body.removeChild(textArea);
};

export const handleCopy = (text: string) => {
  if (!navigator.clipboard) {
    console.error('Clipboard API not available');
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log('Text copied to clipboard!');
    })
    .catch((err) => {
      console.error('Failed to copy: ', err);
    });
  // window.clipboardData.setData("Text", 'Copy this text to clipboard')
  return true;
};

export const reletiveTimeDisplay = (time: string) => {
  dayjs.extend(utc);
  dayjs.extend(relative);
  const relativeTime = dayjs.utc(time);
  return relativeTime.fromNow();
};
