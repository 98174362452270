import { apiRoutes } from 'src/constants';
import { Axios } from 'src/lib/axios';
import { getUserId, handleError, handleSuccess } from 'src/lib/utils';

export const getLoggedInUser = async (userId = getUserId()) => {
  try {
    const response = await Axios.get(apiRoutes.user.profile(userId));
    return handleSuccess(response, 'User fetched success');
  } catch (error) {
    return handleError(error);
  }
};

export const createSession = async (
  isPrivate: boolean,
  userId = getUserId(),
) => {
  try {
    const response = await Axios.post(
      apiRoutes.user.sessions.create(isPrivate),
      {
        userId,
      },
    );
    return handleSuccess(response, 'Session created');
  } catch (error) {
    return handleError(error);
  }
};

export const getSessions = async (userId = getUserId()) => {
  try {
    const response = await Axios.get(apiRoutes.user.sessions.all(userId));
    return handleSuccess(response, 'Sessions fetched');
  } catch (error) {
    return handleError(error);
  }
};

export const getSession = async (sessionId: string, userId = getUserId()) => {
  try {
    const response = await Axios.get(
      apiRoutes.user.sessions.single(sessionId, userId),
    );
    return handleSuccess(response, 'Session  fetched');
  } catch (error) {
    return handleError(error);
  }
};

export const updateSession = async (
  sessionId: string,
  body: any,
  userId = getUserId(),
) => {
  try {
    const response = await Axios.patch(
      apiRoutes.user.sessions.update(sessionId, userId),
      body,
    );
    return handleSuccess(response, 'Session updated..!');
  } catch (error) {
    return handleError(error);
  }
};

const removeLocal = (id: string) => {
  const storedData = localStorage.getItem('detail');
  // Parse the existing data if it exists
  const dataArray = storedData ? JSON.parse(storedData) : [];

  const updateData: { sessionId: string; question: string }[] = [];
  dataArray.forEach((element: { sessionId: string; question: string }) => {
    if (element.sessionId != id) {
      updateData.push(element);
    }
  });
  localStorage.setItem('detail', JSON.stringify(updateData));
};
export const deleteSession = async (
  sessionId: string,
  userId = getUserId(),
) => {
  try {
    const response = await Axios.delete(
      apiRoutes.user.sessions.delete(sessionId, userId),
    );
    removeLocal(sessionId);
    return handleSuccess(response, 'Session deleted');
  } catch (error) {
    return handleError(error);
  }
};

export const deleteAllSessions = async (userId = getUserId()) => {
  try {
    const response = await Axios.delete(
      apiRoutes.user.sessions.deleteAll(userId),
    );
    return handleSuccess(response, 'All session deleted');
  } catch (error) {
    return handleError(error);
  }
};

export const getConversationHistory = async (
  sessionId: string,
  userId = getUserId(),
) => {
  try {
    const response: IChatResponse = await Axios.get(
      apiRoutes.conversation.history(sessionId, userId),
    );
    return handleSuccess(response, 'History fetched success');
  } catch (error) {
    return handleError(error);
  }
};

export const startConversation = async (
  sessionId: string,
  body: any,
  isAdvanced: boolean,
  userId = getUserId(),
) => {
  try {
    const response = await Axios.post(
      apiRoutes.conversation.query(sessionId, userId, isAdvanced),
      body,
    );
    return handleSuccess(response, 'Ans responded');
  } catch (error) {
    return handleError(error);
  }
};

export const scrapeData = async (
  sessionId: string,
  body: any,
  userId = getUserId(),
) => {
  try {
    const response = await Axios.post(
      apiRoutes.dataIndexing.scrapeData(sessionId, userId),
      body,
    );
    return handleSuccess(response, 'File uploaded successfully.');
  } catch (error) {
    return handleError(error);
  }
};

export const deleteFiles = async (sessionId: string, userId = getUserId()) => {
  try {
    const response = await Axios.post(
      apiRoutes.user.sessions.deleteFiles(sessionId, userId),
    );
    return handleSuccess(response, 'Content deleted.');
  } catch (error) {
    return handleError(error);
  }
};
