import { createContext, ReactNode, useContext, useRef } from 'react';
import { TStore } from 'src/@types/types';
import { createMyStore, initStore } from 'src/store';
import { useStore, type StoreApi } from 'zustand';
interface IStoreProvider {
  children: ReactNode;
}
// Create Context
const StoreContext = createContext<StoreApi<TStore> | null>(null);
// Create Context Provider
export const StoreProvider = ({ children }: IStoreProvider) => {
  const storeRef = useRef<StoreApi<TStore>>();
  if (!storeRef.current) {
    storeRef.current = createMyStore(initStore());
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
};

// Create useMyStore hook
export const useMyStore = <T,>(selector: (store: TStore) => T): T => {
  const storeContext = useContext(StoreContext);

  if (!storeContext) {
    throw new Error(`useMyStore must be use within StoreProvider`);
  }

  return useStore(storeContext, selector);
};
StoreContext.displayName = 'Store Context';
