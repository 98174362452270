import { File, Plus, Trash } from 'lucide-react';
import { Button } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Separator } from '../ui/separator';

type TProps = {
  list: string[] | null | undefined;
  Comp?: React.ComponentType;
  onAdd: () => void;
  fileName?: boolean;
  onClear: () => void;
  isLoading?: boolean;
};

export default function AttachedFilePopover({
  list,
  Comp,
  onAdd,
  onClear,
  fileName = true,
  isLoading,
}: TProps) {
  const getFileName = (file: any, start = 0, end = 5) => {
    const extractedPart = file?.substring(start, end);
    const lastDotIndex = file?.lastIndexOf('.');
    let extension = '';
    if (lastDotIndex !== -1) {
      extension = file?.substring(lastDotIndex);
    }
    return `${extractedPart}....${extension}`;
  };
  const displayFileName =
    fileName && list
      ? list.length > 1
        ? `${list.length} Files`
        : getFileName(list[0], 0, 5)
      : '';
  return (
    <>
      {list && (
        <>
          {list.length ? (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  className="gap-1 text-center font-medium text-secondary"
                  disabled={isLoading}
                >
                  <File size={16} />
                  <span className="hidden max-sm:block">{displayFileName}</span>
                  <span className="block max-sm:hidden">
                    {fileName
                      ? list.length > 1
                        ? `${list.length} Files`
                        : list[0].length > 20
                          ? getFileName(list[0], 0, 25)
                          : list[0]
                      : ''}
                  </span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-100 border p-0 max-sm:mx-[20px] max-sm:mt-2 max-sm:w-[100%] md:min-w-96">
                <div className="px-4 py-4">
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">Attached Files</span>
                    <div className="flex items-center gap-4">
                      <Button
                        onClick={onAdd}
                        className="flex h-0 gap-1 p-0 text-center text-xs text-primary-foreground"
                      >
                        <Plus size={14} /> Add
                      </Button>
                      <Button
                        onClick={onClear}
                        className="flex h-0 gap-1 p-0 text-center text-xs text-primary-foreground"
                      >
                        {' '}
                        <Trash size={14} /> Clear
                      </Button>
                    </div>
                  </div>
                </div>
                <Separator />
                <div className="flex flex-col gap-2 px-3 py-2">
                  {list.map((item, i) => (
                    <div
                      className="flex items-center gap-4 text-center"
                      key={i}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-md bg-black/10 text-primary">
                        <File size={20} />
                      </div>
                      <div className="hidden text-primary lg:block">{item}</div>
                      <div className="block text-primary lg:hidden">
                        {getFileName(item, 0, 20)}
                      </div>
                    </div>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          ) : (
            Comp && <Comp />
          )}
        </>
      )}
    </>
  );
}
