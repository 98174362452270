import axios, { AxiosError } from 'axios';
// import { signOut } from './actions';
import { signOut } from 'src/services/authService';
import cookies from 'js-cookie';
import { accessTokenName, baseURL } from 'src/constants';

export const Axios = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.request.use(
  (config) => {
    config.headers;
    const token = cookies.get(accessTokenName);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    console.log('Request Error : ', error);
    return Promise.reject(error);
  },
);
Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      // Request made and server responded
      switch (error.response.status) {
        case 400:
          console.log('Bad Request');
          break;
        case 401:
          unAuthorize();
          //   window.location.href = DEFAULT_REDIRECT_PATH_AFTER_LOGOUT;
          break;
        case 403:
          console.log('Forbidden');
          break;
        case 404:
          console.log('Not Found');
          break;
        case 500:
          console.log('Internal Server Error');
          break;
        case 502:
          console.log('Bad Gateway');
          break;
        case 503:
          console.log('Service Unavailable');
          break;
        case 504:
          console.log('Gateway Timeout');
          break;
        default:
          break;
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log('Error Request : ', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error Message : ', error.message);
    }
    return Promise.reject(error);
  },
);
export const unAuthorize = () => {
  delete Axios.defaults.headers.common['Authorization'];
  signOut();
  window.location.reload();
};
