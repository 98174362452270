import { FC, ReactNode } from 'react';
import { Button } from '../ui/button';
import { Loader } from 'lucide-react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  pending?: boolean;
}
const SubmitButton: FC<Props> = ({
  children = 'Submit',
  pending,
  ...props
}) => {
  //   const { pending } = useFormStatus();
  return (
    <Button
      variant="secondary"
      disabled={pending}
      type="submit"
      className="flex w-full items-center gap-2 py-6"
      {...props}
    >
      {pending ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : ''}
      {children && children}
    </Button>
  );
};

export default SubmitButton;
