import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import './index.css';
import { StoreProvider } from './context/store';
import ModalsWrapper from './components/modals/modal-wrapper';
import { Toaster } from './components/ui/sonner';
import { ThemeProvider } from './components/theme/theme-provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider>
        <ThemeProvider
          attribute="class"
          defaultTheme="system"
          enableSystem
          disableTransitionOnChange
        >
          <App />
          <Toaster richColors={true} />
          <ModalsWrapper />
        </ThemeProvider>
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
