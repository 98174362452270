import { Search } from 'lucide-react';
import { Input } from '../ui/input';

export default function LibSearchBar({
  onSearch,
}: {
  onSearch: (value: string) => void;
}) {
  return (
    <div className="relative w-full">
      <Input
        onChange={(e) => onSearch(e.target.value)}
        placeholder="Search your threads"
        className="border-gray-200 bg-white p-2 py-4 pl-16 dark:border-[#333333] dark:bg-black/50 dark:text-white dark:placeholder:text-[#8d8d8d]"
      />
      <div className="absolute left-3 top-2 text-gray-400">
        <Search />
      </div>
    </div>
  );
}
