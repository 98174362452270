import { ReactNode } from 'react';
import { Button } from '../ui/button';
import { ArrowRight } from 'lucide-react';

type TProps = {
  Icon: ReactNode;
  title: string;
  viewMore?: boolean;
  handleViewMore?: () => void;
};

export default function SearchPageHeading(props: TProps) {
  const { Icon, title, viewMore = false } = props;
  return (
    <div className="flex justify-between">
      <div className="flex items-center justify-start gap-4 text-xl text-black dark:text-white">
        <span>{Icon}</span>
        <h1>{title}</h1>
      </div>
      {viewMore && (
        <Button
          onClick={props.handleViewMore}
          className="flex items-center justify-end gap-1 text-center text-xs font-normal text-[#8d8d8d]"
          variant={'ghost'}
        >
          View more <ArrowRight size={12} />
        </Button>
      )}
    </div>
  );
}
