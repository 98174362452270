import { z } from 'zod';

// Sign In Form Schema
export const SignInFormSchema = z.object({
  email: z
    .string()
    .min(1, 'Email is required')
    .email({ message: 'Invalid email address' }),
  password: z
    .string()
    .min(1, 'Password is required')
    .min(8, { message: 'Password must be at least 8 characters long' }),
});
export type SignInFormData = z.infer<typeof SignInFormSchema>;
