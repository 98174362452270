import { Dispatch, SetStateAction, useState } from 'react';
import { Sheet, SheetClose, SheetContent, SheetHeader } from '../ui/sheet';
import { Separator } from '../ui/separator';
import { Button } from '../ui/button';
import { X } from 'lucide-react';
import { Slider } from '../ui/slider';
import { useThreadStore } from 'src/store/useThreadStore';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PrivateDetail({ open, setOpen }: TProps) {
  const {
    temperatureValue,
    setTemperatureValue,
    topKValue,
    setTopKValue,
    modelName,
    setModalName,
  } = useThreadStore();
  const [isOpen, setIsOpen] = useState(false);
  // const [temperatureValue, setTemperatureValue] = useState([0]);
  // const [topKValue, setTopKValue] = useState([0]);

  const handleSliderChange = (values: number[]) => {
    console.log(values, 'values');

    setTemperatureValue(values[0]);
  };

  const handleSliderChangeTopK = (values: number[]) => {
    setTopKValue(values[0]);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleItemClick = (item: any) => {
    setModalName(item);
    setIsOpen(false);
  };
  return (
    <Sheet open={open} onOpenChange={() => setOpen(false)}>
      <SheetContent
        side="right"
        className="mb-4 w-[400px] overflow-y-scroll bg-white p-0 dark:bg-background max-sm:w-full sm:w-[300px]"
      >
        <div className="sticky top-0 z-50 bg-white dark:bg-background">
          <div className="relative px-3 py-4 ">
            <SheetHeader className="p-4">
              <SheetClose asChild className="absolute right-0 top-3">
                <Button className="h-9 w-9 p-0" variant="ghost">
                  <X size={20} />
                </Button>
              </SheetClose>
            </SheetHeader>
          </div>
          <Separator />

          <div className="px-5">
            <div className="mt-5 text-[#64645F] dark:text-white">Model</div>
            <div
              className={`hs-dropdown relative mt-2 ${isOpen ? 'hs-dropdown-open' : ''}`}
            >
              <button
                id="hs-dropdown-default"
                type="button"
                onClick={toggleDropdown}
                className="hs-dropdown-toggle inline-flex w-full items-center justify-between rounded-lg border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-white dark:hover:bg-neutral-800"
              >
                {modelName ? modelName : 'Select Modal'}{' '}
                <svg
                  className={`size-4 ${isOpen ? 'hs-dropdown-open:rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </button>

              <div
                className={`hs-dropdown-menu duration transition-[opacity,margin] ${
                  isOpen ? 'visible opacity-100' : 'hidden opacity-0'
                } mt-2 min-w-60 rounded-lg bg-white p-2 shadow-md before:absolute before:-top-4 before:start-0 before:h-4 before:w-full after:absolute after:-bottom-4 after:start-0 after:h-4 after:w-full dark:divide-neutral-700 dark:border dark:border-neutral-700 dark:bg-neutral-800`}
                aria-labelledby="hs-dropdown-default"
              >
                <div
                  className="flex cursor-pointer items-center gap-x-3.5 rounded-lg px-3 py-2 text-sm text-gray-800 hover:bg-accent focus:bg-accent focus:outline-none dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
                  onClick={() => handleItemClick('mistral-large')}
                >
                  mistral-large
                </div>
                <div
                  className="flex cursor-pointer items-center gap-x-3.5 rounded-lg px-3 py-2 text-sm text-gray-800 hover:bg-accent focus:bg-accent focus:outline-none dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
                  onClick={() => handleItemClick('claude-3-sonnet')}
                >
                  claude-3-sonnet
                </div>
              </div>
            </div>
            <div className="mt-[9%] text-[#64645F] dark:text-white">
              Parameters
            </div>
            <div className="flex flex-col gap-3 pt-3">
              <div className="flex justify-between">
                <label className="font-semibold text-[#333333] dark:text-white">
                  Temperature
                </label>
                <div className="flex h-[30px] w-[30px] items-center justify-center rounded-[10px] bg-[#F8F8F8] p-2 text-[14px] font-semibold text-[#333333]">
                  {temperatureValue?.toString()}
                </div>
              </div>
              <Slider
                max={1}
                step={0.1}
                defaultValue={[temperatureValue]}
                onValueChange={handleSliderChange}
              />
            </div>

            {/* <div className="flex flex-col gap-3 pt-3">
              <div className="flex justify-between">
                <label className="font-semibold text-[#333333] dark:text-white">
                  Top-K
                </label>
                <div className="flex h-[30px] w-[30px] items-center justify-center rounded-[10px] bg-[#F8F8F8] p-2 text-[14px] font-semibold text-[#333333]">
                  {topKValue.toString()}
                </div>
              </div>
              <Slider
                max={1}
                step={0.1}
                value={[topKValue]}
                onValueChange={handleSliderChangeTopK}
              />{' '}
            </div> */}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
