import { cn } from 'src/lib/utils';

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'animate-pulse rounded-md bg-gray-200 dark:bg-foreground',
        className,
      )}
      {...props}
    />
  );
}

export { Skeleton };
