import {
  IndentDecrease,
  IndentIncrease,
  LogOut,
  MoreHorizontal,
  Palette,
} from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ModalNames, SidebarItems } from 'src/@types/types';
import { SidebarButton } from './sidbar-button';
import { Separator } from '../ui/separator';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Button } from '../ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { useMyStore } from 'src/context/store';
import { Logo } from '../icons';
import { signOut } from 'src/services/authService';
import { toast } from 'sonner';
import { useEffect, useState } from 'react';
import { getLoggedInUser } from 'src/services/userService';
import { useThreadStore } from 'src/store/useThreadStore';
import { useTheme } from 'next-themes';
import { Switch } from '../ui/switch';

interface DesktopSidebarProps {
  sidebarItems: SidebarItems;
}

export function DesktopSidebar(props: DesktopSidebarProps) {
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const { setIsRecent } = useThreadStore();
  const {
    toggleDrawer,
    toggleModal,
    drawer,
    user,
    isUserLoggedIn,
    setUserStatus,
    setUser,
  } = useMyStore((state) => state);
  const { setTheme, theme, resolvedTheme } = useTheme();
  // console.log(theme, 'theme', resolvedTheme);
  const navigate = useNavigate();
  const [isDark, setIsDark] = useState(resolvedTheme === 'dark');
  useEffect(() => {
    setIsDark(resolvedTheme === 'dark');
  }, [resolvedTheme]);

  const handleLogout = async () => {
    setIsRecent(true);
    const res = await signOut();
    if (res.status)
      setUserStatus(false), toast.success(res.message), navigate('/');
    else toast.error(res.message);
  };

  const getUser = async () => {
    setUser((await getLoggedInUser()).data);
  };

  const changeTheme = () => {
    setIsDark(!isDark);
    const targetTheme = resolvedTheme === 'dark' ? 'light' : 'dark';
    console.log('theme change', targetTheme);

    setTheme(targetTheme);
  };

  useEffect(() => {
    getUser();
  }, [isUserLoggedIn]);

  const onButtonClick = (modal: ModalNames) => {
    toggleModal(modal, true);
  };

  const toggleThread = () => {
    toggleModal(ModalNames.THREAD, true);
  };
  const handleDelete = async (id: string) => {
    console.log('id---', id);
    localStorage.removeItem('detail');
    setIsRecent(true);
  };
  return (
    <aside
      className={`fixed left-0 top-0 z-40 h-screen ${drawer ? 'w-[100px]' : 'w-[300px]'} max-w-xs border-r  bg-white transition-all duration-300 ease-in-out dark:bg-foreground`}
    >
      <div
        className={`h-full ${drawer ? 'flex flex-col items-center' : 'px-8 '} py-4  transition-all duration-300 ease-in-out`}
      >
        <div className="relative -mt-1 flex flex-col gap-3 transition-all duration-300 ease-in-out ">
          <span className={`${drawer ? 'mt-3' : ''}`}>
            <Logo name={drawer ? 'logo' : isDark ? 'logoDark' : 'withname'} />
          </span>
          <div className="absolute -right-14 top-[5px] flex items-center justify-center">
            <Button
              onClick={() => toggleDrawer()}
              variant={'default'}
              size={'sm'}
              className="bg-white !px-1 !py-0 text-black hover:bg-slate-50 dark:bg-foreground dark:text-secondary dark:hover:bg-accent"
            >
              {drawer ? (
                <IndentIncrease size={32} />
              ) : (
                <IndentDecrease size={32} />
              )}
            </Button>
          </div>
          {props.sidebarItems.extras(drawer, toggleThread)}
        </div>
        <div className="mt-8">
          <div className="flex w-full flex-col gap-3 ">
            {props.sidebarItems.links.map((link, index) =>
              link.modal && !isUserLoggedIn ? (
                <SidebarButton
                  onClick={() => onButtonClick(link.modal!)}
                  key={index}
                  variant={'default'}
                  icon={link.icon}
                  className="w-full dark:text-accent-foreground"
                >
                  {drawer ? '' : link.label}
                </SidebarButton>
              ) : (
                index <= 1 && (
                  <Link key={index} to={link.href}>
                    <SidebarButton
                      variant={pathname === link.href ? 'sidebar' : 'default'}
                      icon={link.icon}
                      className="w-full dark:text-accent-foreground"
                    >
                      {drawer ? '' : link.label}
                    </SidebarButton>
                  </Link>
                )
              ),
            )}
          </div>
          <div>
            {!drawer &&
              props.sidebarItems.subLink
                // .filter((item) => item.isPrivate === isPrivate)
                .map((link, index) => {
                  return isUserLoggedIn ? (
                    <div
                      key={link.key}
                      className="mb-lg relative ml-[23px] mt-[8px] border-l pl-[8px] duration-200 dark:border-secondary"
                    >
                      <div>
                        <Link to={link.href}>
                          <div className="group cursor-pointer rounded bg-transparent p-[6px] transition duration-300 hover:bg-[#f5f5f5] dark:hover:bg-accent">
                            <div className="gap-x-xs light flex items-center justify-between font-sans text-xs font-medium text-[#f5f5f5]">
                              <div className="truncate">
                                <span className="mt-[6px] text-[13px] text-[#84847f] dark:text-white">
                                  {link.label}
                                </span>
                              </div>
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                width={18}
                                height={18}
                                data-prefix="far"
                                data-icon="layer-group"
                                className="svg-inline--fa fa-layer-group fa-fw animate scale-90 opacity-0 duration-300 ease-in-out group-hover:scale-100 group-hover:opacity-100"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                              >
                                <path
                                  fill="#84847f"
                                  d="M288 0c-8.5 0-17 1.7-24.8 5.1L53.9 94.8C40.6 100.5 32 113.5 32 128s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 5.1C305 1.7 296.5 0 288 0zm-5.9 49.2C284 48.4 286 48 288 48s4 .4 5.9 1.2L477.7 128 293.9 206.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 128 282.1 49.2zM53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 235.5 477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256 146 235.5 85.1 209.4 53.9 222.8zm0 128C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4 53.9 350.8z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="absolute right-[100%] top-0 !m-0 transform-gpu opacity-0 delay-200 duration-200 hover:!opacity-100 hover:delay-0 group-hover/history:opacity-50">
                        {index === 0 ? (
                          <button
                            type="button"
                            className="cursor-point relative inline-flex aspect-square h-6 origin-center cursor-pointer select-none items-center justify-center whitespace-nowrap rounded-full text-center font-sans text-xs font-medium outline-none outline-transparent transition duration-300 ease-in-out focus:outline-none active:scale-95"
                            data-state="closed"
                            onClick={() => handleDelete(link.sessionId)}
                          >
                            <div className="gap-xs flex items-center justify-center leading-none">
                              <svg
                                aria-hidden="true"
                                width={14}
                                height={14}
                                focusable="false"
                                data-prefix="far"
                                data-icon="xmark"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                              >
                                <path
                                  fill="#84847f"
                                  d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"
                                ></path>
                              </svg>
                            </div>
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  );
                })}
          </div>
          {/* {!drawer && !isUserLoggedIn && (
            <div className="mt-6 flex w-full flex-col gap-3">
              {props.sidebarItems.auth.map((item, index) => (
                <SidebarButton
                  onClick={() => onButtonClick(item.modal!)}
                  key={index}
                  icon={item.icon}
                  className="w-full"
                  variant={'outline'}
                >
                  {item.label}
                </SidebarButton>
              ))}
            </div>
          )} */}
          <div className="absolute bottom-3 left-0 w-full px-3">
            {isUserLoggedIn && (
              <>
                <Separator className="absolute -top-3 left-0 w-full" />
                <Popover>
                  <Button
                    variant="ghost"
                    className="hover:text-black-300 w-full cursor-default justify-start dark:text-accent-foreground"
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-2">
                        <Avatar className="h-8 w-8">
                          <AvatarImage src="" alt="profilePic" />
                          <AvatarFallback>
                            {user?.userName.charAt(0).toUpperCase() || 'A'}
                          </AvatarFallback>
                        </Avatar>
                        {drawer ? '' : <span>{user?.userName}</span>}
                      </div>
                      <PopoverTrigger asChild>
                        <MoreHorizontal
                          size={20}
                          className={
                            isHovered
                              ? 'icon-hovered cursor-pointer'
                              : 'cursor-pointer'
                          }
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        />
                      </PopoverTrigger>
                    </div>
                  </Button>
                  <PopoverContent className="mb-2 w-56 rounded-[1rem] p-3 dark:bg-foreground">
                    <div className="space-y-1">
                      <div className="flex h-9 w-full items-center justify-between gap-2 whitespace-nowrap rounded-md text-sm font-medium text-primary-foreground ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:pointer-events-none disabled:opacity-50">
                        <SidebarButton
                          size="sm"
                          icon={Palette}
                          className="w-full dark:text-accent-foreground"
                        >
                          Dark
                        </SidebarButton>
                        <Switch
                          checked={isDark}
                          onCheckedChange={changeTheme}
                        />
                      </div>
                      <SidebarButton
                        onClick={handleLogout}
                        size="sm"
                        icon={LogOut}
                        className="w-full dark:text-accent-foreground"
                      >
                        Log Out
                      </SidebarButton>
                      {/* <ThemeToggle/> */}
                    </div>
                  </PopoverContent>
                </Popover>
              </>
            )}
          </div>
        </div>
      </div>
    </aside>
  );
}
