import { ModalNames, TStates, TStore } from 'src/@types/types';
import { accessTokenName } from 'src/constants';
import { checkIsUserLoggedIn } from 'src/lib/utils';
import { createStore } from 'zustand';
import Cookies from 'js-cookie';

const isUserLogged = checkIsUserLoggedIn(Cookies.get(accessTokenName));

export const initStore = (): TStates => {
  return {
    // AUTH INITIAL STATES
    isUserLoggedIn: isUserLogged,
    drawer: false,
    // MODAL INITIAL STATES
    isModalOpen: !isUserLogged,
    authModalName: !isUserLogged ? ModalNames.SIGNIN : ModalNames.NULL,
    user: {
      userName: '',
      email: '',
      password: '',
    },
  };
};

export const initialStates: TStates = initStore();

// SLICE CREATOR
export const createMyStore = (initState: TStates = initialStates) => {
  return createStore<TStore>()((set) => ({
    ...initState,
    // AUTH ACTIONS
    setUserStatus: (flag) =>
      set((state) => ({ ...state, isUserLoggedIn: flag })),
    setUser: (user) => set((state) => ({ ...state, user })),
    toggleDrawer: () => set((state) => ({ ...state, drawer: !state.drawer })),
    // MODAL ACTIONS
    toggleModal: (name, flag) =>
      set((state) => ({
        ...state,
        authModalName: name,
        isModalOpen: flag ?? !state.isModalOpen,
      })),
  }));
};
