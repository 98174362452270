import { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

interface TProps {
  onChangeValue: (value: boolean | string) => void;
}
const LibSelect = ({ onChangeValue }: TProps) => {
  const [modelName, setModalName] = useState<string>('all');
  const handleItemClick = (item: string) => {
    setModalName(item);
    onChangeValue(item);
  };

  return (
    <Select
      defaultValue="all"
      value={modelName}
      onValueChange={(value) => handleItemClick(value)}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="all">All</SelectItem>
          <SelectItem value="private">Private</SelectItem>
          <SelectItem value="public">Public</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default LibSelect;
