import {
  accessTokenName,
  recomandedList,
  refreshTokenName,
} from 'src/constants';
import { Button } from '../ui/button';
import { createSession } from 'src/services/userService';
import { useThreadStore } from 'src/store/useThreadStore';
import { useNavigate } from 'react-router-dom';

export default function Recomanded() {
  const navagate = useNavigate();
  const {
    startThread,
    setThread,
    setIsNew,
    isPrivate,
    modelName,
    temperatureValue,
  } = useThreadStore();
  const handleClick = async (question: string) => {
    setThread([]);
    const res = await createSession(isPrivate);
    if (res.status) {
      setIsNew(true);
      startThread(
        res.data?.sessionId,
        {
          question,
          modelName: isPrivate
            ? modelName
              ? modelName === 'mistral-large'
                ? 'mistral.mistral-large-2402-v1:0'
                : modelName === 'claude-3-sonnet'
                  ? 'anthropic.claude-3-sonnet-20240229-v1:0'
                  : modelName
              : 'mistral.mistral-large-2402-v1:0'
            : 'gpt-3.5-turbo',
          temperature: isPrivate ? temperatureValue : 0.3,
        },
        isPrivate,
      );
      navagate(`/search/${res.data?.sessionId}`);
    }
  };
  return (
    <div className="grid gap-3 md:items-center md:justify-center md:gap-x-2 lg:grid-cols-2">
      {recomandedList.map((item, i) => (
        <Button
          onClick={() => handleClick(item)}
          key={i}
          className="w-full justify-start rounded-lg bg-white px-4 py-1 text-primary dark:border dark:bg-foreground dark:text-accent-foreground"
        >
          <span>{item}</span>
        </Button>
      ))}
    </div>
  );
}
