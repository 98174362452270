import { Button } from '../ui/button';
import { ArrowRight, CirclePlus, RotateCw, Settings } from 'lucide-react';
import { Textarea } from '../ui/textarea';
import { FormEvent, useState, useRef, useEffect } from 'react';
import {
  createSession,
  deleteFiles,
  scrapeData,
} from 'src/services/userService';
import { useNavigate } from 'react-router-dom';
import { useMyStore } from 'src/context/store';
import { ModalNames } from 'src/@types/types';
import { useThreadStore } from 'src/store/useThreadStore';
import { toast } from 'sonner';
import AttachedFilePopover from './attachedFile';
import { accessTokenName, apiRoutes, refreshTokenName } from 'src/constants';
import Cookies from 'js-cookie';
import { Switch } from '../ui/switch';
import PrivateDetail from '../sidebar/private-detail';

type TScrapState = {
  loading: boolean;
  uploadedContent?: string[];
};

export default function HomeSearch() {
  const navigate = useNavigate();
  const [question, setQuestion] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [scrapState, setScrapState] = useState<TScrapState>({
    loading: false,
    uploadedContent: [],
  });
  const [fileExtension, setFileExtension] = useState<string>(''); // Provide a default value
  const { toggleModal, isUserLoggedIn } = useMyStore((state) => state);
  const {
    startThread,
    setThread,
    setIsNew,
    setIsAdvance,
    isPrivate,
    modelName,
    temperatureValue,
    setIsPrivate,
  } = useThreadStore();

  useEffect(() => {
    setIsPrivate(false);
  }, []);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isAdvanced, setIsAdavance] = useState<boolean>(false);
  const handleSubmit = async (e: FormEvent) => {
    if (!question || scrapState.loading) {
      return;
    }
    e.preventDefault();
    setThread([]);
    let res;
    if (!sessionId) {
      res = await createSession(isPrivate);
      console.log('res', res.data?.sessionId);
      if (res.status) {
        console.log('bfuwcd');
        const session = res.data?.sessionId;

        setSessionId(() => session);
        console.log(res.data?.sessionId, 'uiii', sessionId);
      }
    }
    setIsNew(true);
    startThread(
      sessionId ? sessionId : res?.data?.sessionId,
      {
        question,
        modelName: isPrivate
          ? modelName
            ? modelName === 'mistral-large'
              ? 'mistral.mistral-large-2402-v1:0'
              : modelName === 'claude-3-sonnet'
                ? 'anthropic.claude-3-sonnet-20240229-v1:0'
                : modelName
            : 'mistral.mistral-large-2402-v1:0'
          : 'gpt-3.5-turbo',
        temperature: isPrivate ? temperatureValue : 0.3,
      },
      isPrivate,
      isAdvanced,
    );
    setIsAdvance(isAdvanced);
    navigate(`/search/${sessionId ? sessionId : res?.data?.sessionId}`);
    toggleModal(ModalNames.THREAD, false);
  };
  const handleChangeSwich = (checked: boolean) => {
    setIsPrivate(checked);
  };
  const changeTheme = () => {
    setIsAdavance((prev) => !prev);
  };
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileName = e.target.files?.[0]?.name as string;
    const file = e.target.files?.[0];
    if (!file) return;
    const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB
    if (fileSizeInMB > 200) {
      toast.warning('Please select a file smaller than 200 MB.');
      return;
    }
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      const base64Data = event.target.result.split(',')[1];
      setScrapState((prev) => ({ ...prev, loading: true }));
      let createSe;
      if (!sessionId) {
        createSe = await createSession(isPrivate);
        if (createSe.status) {
          setSessionId(createSe.data?.sessionId);
        }
      }
      const fileExtension1 = fileName.split('.').pop()?.toLowerCase();
      let contentType = '';
      if (fileExtension1) {
        if (fileExtension1 === 'pdf') {
          contentType = 'pdf';
        } else if (fileExtension1 === 'csv') {
          contentType = 'csv';
        } else if (fileExtension1 === 'txt') {
          contentType = 'txt';
        } else if (fileExtension1 === 'xlsx') {
          contentType = 'xlsx';
        } else if (fileExtension1 === 'xls') {
          contentType = 'xls';
        }
        setFileExtension(fileExtension1);
      } else {
        // Handle the case where fileExtension1 is undefined
      }

      console.log('fileExtension', fileExtension1);

      const body = {
        name: fileName,
        contentType,
        fileContent: base64Data,
      };

      const res = await scrapeData(
        sessionId ? sessionId : createSe?.data?.sessionId,
        body,
      );
      if (res.status) {
        toast.message(res.message);
        setScrapState(
          (prev) =>
            ({
              ...prev,
              loading: false,
              uploadedContent: prev.uploadedContent
                ? [...prev.uploadedContent, fileName]
                : [fileName],
            }) as TScrapState,
        );
      } else {
        toast.error(res.message);
        setScrapState((prev) => ({ ...prev, loading: false }));
      }
    };
    reader.readAsDataURL(file);
  };

  const handleAttachClick = () => {
    if (fileInputRef.current) {
      const accessToken = Cookies.get(accessTokenName);
      const refreshToken = Cookies.get(refreshTokenName);

      if (accessToken && refreshToken) {
        fileInputRef.current.click();
      } else {
        toggleModal(ModalNames.SIGNIN, true);
      }
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const accessToken = Cookies.get(accessTokenName);
    const refreshToken = Cookies.get(refreshTokenName);
    if (accessToken && refreshToken) {
      setQuestion(e.target.value);
      console.log('Access Token:', accessToken);
      console.log('Refresh Token:', refreshToken);
    } else {
      // At least one token is missing, show the sign-in modal
      toggleModal(ModalNames.SIGNIN, true);
    }
  };

  const handleClear = async () => {
    const response = await deleteFiles(sessionId as string);
    if (response.status) {
      setScrapState((prev) => ({
        ...prev,
        uploadedContent: [],
        loading: false,
      }));
      toast.success(response.message);
    }
    toast.error(response.message);
  };
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="relative w-full">
      <div className="rounded-md">
        <Textarea
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(e);
            }
          }}
          placeholder="Ask me anything..."
          className="col-start-1 col-end-4 max-h-[45vh] w-full resize-none overflow-auto !border-none bg-white px-5 py-3 text-primary caret-primary-foreground outline-none dark:bg-foreground dark:text-accent-foreground dark:placeholder:text-[#8D8D8D]"
        />
        <div
          className={`grid-rows-1fr-auto flex w-full grid-cols-2 rounded-b-md rounded-t-md border bg-white font-sans text-foreground shadow-sm outline-none transition-all duration-200 dark:border-[#333333] dark:bg-foreground md:grid lg:items-center ${
            fileExtension === 'csv' ||
            fileExtension === 'xlsx' ||
            fileExtension === 'xls' ||
            isPrivate === true
              ? 'flex-col'
              : ''
          }`}
        >
          <div
            className={`col-start-1 row-start-3 flex rounded-l-lg !px-1 pb-2 max-sm:row-start-2 max-sm:pt-2 sm:pt-2 md:pt-2 lg:!pt-2 xl:pt-2  `}
          >
            <div className="flex items-center justify-center text-center">
              <AttachedFilePopover
                onClear={handleClear}
                list={scrapState?.uploadedContent}
                onAdd={handleAttachClick}
                Comp={() => (
                  <Button
                    className="items-center gap-1 dark:text-[#8D8D8D] dark:hover:text-secondary"
                    onClick={handleAttachClick}
                    disabled={scrapState.loading}
                  >
                    <span>
                      {scrapState.loading ? (
                        <RotateCw className="h-4 w-4 animate-spin" />
                      ) : (
                        <CirclePlus size={16} />
                      )}
                    </span>
                    <span>{scrapState.loading ? 'Uploading..' : 'Attach'}</span>
                  </Button>
                )}
              />
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".pdf, .csv, .txt, .xlsx, .xls"
              />
              <Button
                size="sm"
                className="items-center gap-1 hover:!bg-transparent hover:text-primary-foreground dark:!text-[#8D8D8D] dark:hover:text-secondary"
              >
                Private
              </Button>
              <span className="flex items-center">
                <Switch
                  checked={isPrivate}
                  onCheckedChange={handleChangeSwich}
                />
              </span>
            </div>
          </div>
          <div className="lg:col-start-1 lg:col-end-4 lg:row-start-2">
            <hr className="mt-0 border-0 bg-[#EBEBEB] text-[#EBEBEB] dark:bg-[#333333] dark:text-[#333333] lg:h-[1px]"></hr>
          </div>
          <div
            className={`col-start-3 row-start-3 flex items-center justify-end rounded-full px-[10px] dark:bg-foreground max-sm:pb-2 max-sm:pt-2 md:pb-2 md:pt-2 lg:justify-self-end xl:pb-2`}
          >
            {fileExtension === 'csv' ||
            fileExtension === 'xlsx' ||
            fileExtension === 'xls' ? (
              <>
                <Switch checked={isAdvanced} onCheckedChange={changeTheme} />
                <Button
                  className="px-2 font-semibold text-accent-foreground disabled:!text-[#64645e]  dark:bg-foreground dark:text-[#8D8D8D]"
                  disabled={isAdvanced === false}
                >
                  Analyze
                </Button>
              </>
            ) : (
              <div
                className={`flex gap-2 ${
                  fileExtension === 'csv' ||
                  fileExtension === 'xlsx' ||
                  fileExtension === 'xls' ||
                  fileExtension === 'pdf'
                    ? ''
                    : 'max-sm:absolute max-sm:right-0 max-sm:px-2'
                } `}
              >
                {isPrivate ? (
                  <Button
                    onClick={handleDrawerToggle}
                    type="button"
                    className="gap-1 rounded-full bg-gray-100 dark:bg-[#09AC961A] dark:text-secondary dark:hover:bg-accent "
                    size={'icon'}
                  >
                    <span>
                      <Settings size={16} />
                    </span>
                  </Button>
                ) : (
                  ''
                )}
              </div>
            )}
            <Button
              onClick={handleSubmit}
              type="submit"
              className="ml-3 gap-1 rounded-full bg-gray-100 dark:bg-[#09AC961A] dark:text-secondary dark:hover:bg-accent"
              size={'icon'}
            >
              <span>
                <ArrowRight size={16} />
              </span>
            </Button>
            <PrivateDetail open={open} setOpen={setOpen} />
          </div>
        </div>
      </div>
    </div>
  );
}
