import { GalleryHorizontalEnd, Trash2 } from 'lucide-react';
import { useMyStore } from 'src/context/store';

export default function LibHeader() {
  const { drawer } = useMyStore((state) => state);
  return (
    <header
      className={`fixed left-0 top-0 z-10 max-sm:hidden ${drawer ? 'pl-[100px]' : 'pl-[300px]'} w-full border-b bg-gray-50 py-4 transition-all duration-300 ease-in-out dark:bg-background dark:text-white`}
    >
      <div className="lg:px-30 flex items-center justify-between text-center max-sm:px-4  sm:px-8 md:px-14 xl:px-52">
        <div className="flex justify-start gap-2 py-2 text-xl">
          <GalleryHorizontalEnd size={24} />
          <span>Library</span>
        </div>
      </div>
    </header>
  );
}
