import { LucideIcon } from 'lucide-react';
import { Button, ButtonProps } from '../ui/button';
import { SheetClose } from '../ui/sheet';
import { cn } from 'src/lib/utils';

interface SidebarButtonProps extends ButtonProps {
  icon?: LucideIcon;
}

export function SidebarButton({
  icon: Icon,
  className,
  children,
  ...props
}: SidebarButtonProps) {
  return (
    <Button
      variant="default"
      className={cn(`justify-start ${children ? 'gap-2' : ''}`, className)}
      {...props}
    >
      {Icon && <Icon size={20} />}
      <span>{children}</span>
    </Button>
  );
}

export function SidebarButtonSheet(props: SidebarButtonProps) {
  return (
    <SheetClose asChild>
      <SidebarButton {...props} />
    </SheetClose>
  );
}

export function SidebarNewThreadButton({
  icon: Icon,
  className,
  children,
  ...props
}: SidebarButtonProps) {
  return (
    <Button
      className={cn(`justify-start ${children ? 'gap-2' : ''}`, className)}
      {...props}
    >
      {children ?? <span>{children}</span>}
      {Icon && <Icon size={20} />}
    </Button>
  );
}
