import { z } from 'zod';

export const ResetPasswordFormSchema = z.object({
  email: z
    .string()
    .min(1, 'Email is required')
    .email({ message: 'Invalid email address' }),
  otp: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.',
  }),

  new_password: z
    .string()
    .min(1, 'Password is required')
    .min(8, { message: 'Password must be at least 8 characters long' }),
});
export type ResetPasswordFormData = z.infer<typeof ResetPasswordFormSchema>;
