import { Loader, MoreHorizontal } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LibCardSkelton from 'src/components/library/card-skelton';
import LibHeader from 'src/components/library/lib-header';
import LibSearchBar from 'src/components/library/lib-searchbar';
import LibSelect from 'src/components/library/lib-select';
import LibraryCard from 'src/components/library/library-card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { useMyStore } from 'src/context/store';
import { deleteAllSessions, getSessions } from 'src/services/userService';
import { useThreadStore } from 'src/store/useThreadStore';

export default function Library() {
  const [data, setData] = useState<ILibraryResponse[]>([]);
  const { isUserLoggedIn } = useMyStore((state) => state);
  const { setIsNew, setIsRecent, isPrivate } = useThreadStore();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchData, setSearchData] = useState<ILibraryResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  const getSessionData = async () => {
    const response = await getSessions();
    if (response.status) {
      setData(response.data?.reverse());
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSessionData();
  }, [isUserLoggedIn]);

  const handleALlDelete = async () => {
    setDelLoading(true);
    const res = await deleteAllSessions();
    if (res.status) {
      setData([]);
      setDelLoading(false);
    }
  };

  const handleClick = (id: string, question?: string) => {
    const storedData = localStorage.getItem('detail');
    let dataArray = storedData ? JSON.parse(storedData) : [];
    dataArray = dataArray.filter((item: TRecent) => item.sessionId != id);
    dataArray.push({ sessionId: id, question, isPrivate });
    localStorage.setItem('detail', JSON.stringify(dataArray));
    setData(dataArray);
    navigate(`/search/${id}`);
    setIsNew(false);
    setIsRecent(true);
  };

  const handlePrivate = (value: boolean | string) => {
    if (value != 'all') {
      setSearchData(
        data.filter((item) => {
          if (value === 'private') {
            return item.isPrivate === true;
          } else {
            return item.isPrivate === false;
          }
        }),
      );
    } else {
      setSearchData(data);
    }
  };
  useEffect(() => {
    if (searchValue.trim() !== '') {
      // Trim the search value
      setSearchData(
        data.filter((item) =>
          item.sessionName
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()),
        ),
      );
    } else {
      setSearchData(data); // If searchValue is empty, show all data
    }
  }, [searchValue, data]);

  return (
    <>
      <LibHeader />
      <div className="lg:px-30 mt-4 flex w-full flex-col gap-6 px-2 py-20 max-sm:px-4  sm:px-8 md:px-14 xl:px-52">
        <div className="flex justify-between gap-5">
          <LibSearchBar onSearch={(value) => setSearchValue(value)} />
          <LibSelect onChangeValue={(value) => handlePrivate(value)} />
        </div>
        <div className="flex justify-between">
          <h1 className="text-2xl font-medium text-black dark:text-white">
            Threads
          </h1>
          <div className="flex justify-start gap-4 text-center text-xs text-gray-500 dark:text-gray-100">
            {data?.length ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button className="rounded-md bg-gray-200 p-1 px-2 dark:bg-foreground">
                    {delLoading ? (
                      <Loader className="h-4 w-4 animate-spin" />
                    ) : (
                      <MoreHorizontal size={18} />
                    )}
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="center"
                  className="dark:bg-background"
                >
                  <DropdownMenuItem
                    onClick={handleALlDelete}
                    className="cursor-pointer "
                  >
                    Delete all
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              ''
            )}
          </div>
        </div>
        {loading ? (
          <LibCardSkelton rows={5} />
        ) : searchData?.length ? (
          searchData.map((item, i) => (
            <div
              className="cursor-pointer"
              key={i}
              onClick={() => handleClick(item.sessionId, item.sessionName)}
            >
              <LibraryCard item={item} setData={setData} />
            </div>
          ))
        ) : (
          <span className="flex w-full justify-center text-center text-gray-500  dark:text-white">
            Threads not found !
          </span>
        )}
      </div>
    </>
  );
}
