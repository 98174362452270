import {
  GalleryHorizontalEnd,
  LogIn,
  Plus,
  Search,
  SquarePlus,
  Delete,
} from 'lucide-react';
import { useMediaQuery } from 'usehooks-ts';
import { SidebarNewThreadButton as SidebarButton } from './sidbar-button';
import { DesktopSidebar } from './desktop-sidebar';
import { MobileSidebar } from './mobile-sidebar';
import { ModalNames, SidebarItems } from '../../@types/types';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { accessTokenName, refreshTokenName } from 'src/constants';
import { useMyStore } from 'src/context/store';
import {
  deleteAllSessions,
  deleteSession,
  getSessions,
} from 'src/services/userService';
import { useNavigate } from 'react-router-dom';
import { useThreadStore } from 'src/store/useThreadStore';

export function Sidebar() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 640px)', {
    initializeWithValue: false,
  });

  const [recentData, setRecentData] = useState<TRecent[]>([]);
  const { isRecent, setIsRecent } = useThreadStore();

  useEffect(() => {
    const storedDataString = localStorage.getItem('detail');
    const storedDataArray: [] = storedDataString
      ? JSON.parse(storedDataString)
      : [];
    setRecentData(storedDataArray.reverse());
    setIsRecent(false);
  }, [isRecent]);

  const sidebarItems = {
    links: [
      { label: 'Home', href: '/', icon: Search },
      {
        label: 'Library',
        href: '/library',
        icon: GalleryHorizontalEnd,
      },
      // { label: 'Sign In', icon: LogIn, modal: ModalNames.SIGNIN, href: '/' },
    ],
    subLink: [
      ...recentData.slice(0, 7).map((item: TRecent, i: number) => {
        const truncatedLabel = item.question || 'NA';
        return {
          key: i,
          label: truncatedLabel,
          icon: Delete,
          sessionId: item.sessionId,
          href: `/search/${item.sessionId}`,
          isPrivate: item.isPrivate,
        };
      }),
    ],

    auth: [{ label: 'Sign Up', modal: ModalNames.SIGNUP }],
    extras: (drawer = false, onClick: any) => {
      return (
        <div className="mt-5">
          <SidebarButton
            onClick={onClick}
            className="w-full justify-between dark:bg-[#2c2c2c] dark:bg-foreground dark:!text-secondary"
            variant="outline"
            icon={drawer ? Plus : SquarePlus}
          >
            {drawer ? null : 'New Thread'}
          </SidebarButton>
        </div>
      );
    },
  };

  if (isDesktop) {
    return <DesktopSidebar sidebarItems={sidebarItems} />;
  }

  return <MobileSidebar sidebarItems={sidebarItems} />;
}
