import { zodResolver } from '@hookform/resolvers/zod';
import { EyeIcon, EyeOff } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import SubmitButton from './submit-button';
import AuthLink from './auth-links';
import { routes } from 'src/pages';
import { ModalNames } from 'src/@types/types';
import { SignUpFormData, SignUpFormSchema } from 'src/lib/schema/signup';
import { signUp } from 'src/services/authService';
import { useMyStore } from 'src/context/store';
import { toast } from 'sonner';

const SignUpForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { toggleModal } = useMyStore((state) => state);

  const onShowPasswordClick = () => {
    setShowPassword((s) => !s);
  };
  // React Hook Form
  const form = useForm<SignUpFormData>({
    resolver: zodResolver(SignUpFormSchema),
  });

  const onSignUp = async (values: SignUpFormData) => {
    setLoading(true);
    const res: TResponse = await signUp(values);
    if (res.status) {
      toast.success(res.message);
      toggleModal(ModalNames.VERIFY_OTP, true);
      localStorage.setItem('email', values.email);
      setLoading(false);
    } else {
      toast.error(res.message);
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-2 dark:text-primary"
        onSubmit={form.handleSubmit(onSignUp)}
      >
        <FormField
          control={form.control}
          name="userName"
          render={({ field }) => (
            <FormItem className="relative pb-5">
              <FormLabel>User Name</FormLabel>
              <FormControl className="py-6">
                <Input
                  {...field}
                  placeholder="Enter your username"
                  type="text"
                  inputMode="text"
                />
              </FormControl>
              <FormMessage className="absolute bottom-0 text-xs" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="relative pb-5">
              <FormLabel>Email address</FormLabel>
              <FormControl className="py-6">
                <Input
                  {...field}
                  placeholder="Enter your email"
                  type="email"
                  inputMode="email"
                />
              </FormControl>
              <FormMessage className="absolute bottom-0 text-xs" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="relative pb-5">
              <FormLabel>Password</FormLabel>
              <div className="flex">
                <FormControl className="rounded-br-none rounded-tr-none py-6">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    {...field}
                    placeholder="**********"
                  />
                </FormControl>
                <button
                  type="button"
                  onClick={onShowPasswordClick}
                  className="flex cursor-pointer items-center justify-center rounded-br-md rounded-tr-md border border-l-0 border-input px-2"
                >
                  {showPassword ? <EyeIcon /> : <EyeOff />}
                </button>
              </div>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
        <SubmitButton pending={loading}>Sign Up</SubmitButton>
        <p className="text-center">
          Already have an account?{' '}
          <AuthLink path={routes[0].path} modal={ModalNames.SIGNIN}>
            Sign in
          </AuthLink>
        </p>
      </form>
    </Form>
  );
};

export default SignUpForm;
