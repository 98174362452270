import { z } from 'zod';

export const VerifyOtpSchema = z.object({
  email: z
    .string()
    .min(1, 'Email is required')
    .email({ message: 'Invalid email address' }),
  otp: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.',
  }),
});
export type VerifyOtpData = z.infer<typeof VerifyOtpSchema>;
